import React from 'react' 
import Inban from '../components/inban/inban'
import Blogs from '../components/blogs/blogs'


function blog() {

  const pageclass = "inbanz1"; // Data for bantitle
  const pagetitle = "Blogs"; // Data for bantitle
  const pagedescription = "Anyone can post"; // Data for bandescription


  return (
    <div> 
      <Inban pageclass={pageclass}  pagetitle={pagetitle} pagedescription={pagedescription} />
      <Blogs/> 
    </div>
  )
}

export default blog