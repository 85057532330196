import React from 'react' 
import Forgotbox from '../components/forgotbox/forgotbox' 



function Forgot() {
  
  return (
    <div> 
      <Forgotbox/>  
    </div>
  )
}

export default Forgot