import React from 'react'
import Carousel from 'react-bootstrap/Carousel'; 

import './slider.css';

import slid1 from "../../images/slide1.jpg"
import slid2 from "../../images/slide2.jpg"
import slid3 from "../../images/slide3.jpg"
import slid4 from "../../images/slide4.jpg"
import slid5 from "../../images/slide5.jpg"

function slider() {
  return (
    <div>
        <Carousel>
      <Carousel.Item interval={3000}>
        <img src={slid1} alt="" /> 
        <Carousel.Caption>
          <h3>Share Your Unique Story and Inspire the World</h3>
          <p>Every blog post you write is a chance to make a meaningful impact on your readers.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={3000}>
      <img src={slid2} alt="" /> 
        <Carousel.Caption>
          <h3>Your Words Have the Power to Change Lives</h3>
          <p>Transform your thoughts into powerful blogs that resonate and connect with a global audience.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item  interval={3000}>
      <img src={slid3} alt="" /> 
        <Carousel.Caption>
          <h3>Blog with Passion and Write with a Clear Purpose</h3>
          <p>
          Turn your creative ideas into captivating stories that captivate and motivate your readers.
          </p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item  interval={3000}>
      <img src={slid4} alt="" /> 
        <Carousel.Caption>
          <h3>Inspire Your Audience Through Thoughtful Posts</h3>
          <p>
          Share your personal journey and unique experiences to uplift and encourage others.
          </p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item  interval={3000}>
      <img src={slid5} alt="" /> 
        <Carousel.Caption>
          <h3>Write, Share, and Influence the World Around You</h3>
          <p>
          Empower your readers with each post, and build a thriving community around your ideas.
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
    </div>
  )
}

export default slider