import React from 'react'
 
import Registerbox from '../components/registerbox/registerbox' 

function register() {
  return (
    <div> 
        <Registerbox/> 
    </div>
  )
}

export default register