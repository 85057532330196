import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { useNavigate, useParams } from 'react-router-dom';
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";
import './bloglistbox.css';

const BlogListBox = () => {
  const { id } = useParams();
  const [posts, setPosts] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));

  const fetchPosts = async () => {
    try {
      const response = await axios.get('https://verbatdemos.com/clickpost/api/endpoints/view_posts.php');
      setPosts(response.data.data); 
    } catch (error) {
      console.error("There was an error fetching the posts!", error);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, []); // No issues here, as it runs only once

  useEffect(() => {
    if (user && user.id) {
      const userPosts = posts.filter(post => post.user_id === user.id);
      setFilteredPosts(userPosts); // This is fine if posts or user change triggers it correctly
    }
  }, [posts, user]);

  const handleEdit = (postId) => {
    navigate(`/blogedit/${postId}`);
  };

const handleDelete = async (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this post?");
    if (confirmDelete) {
      try {
        console.log(`Attempting to delete post with id: ${id}`);
        const response = await axios.get(`https://verbatdemos.com/clickpost/api/endpoints/delete_post.php?post_id=${id}`);
        console.log('Response:', response);
        if (response.status === 200) {
          setPosts(posts.filter(post => post.id !== id));
          alert("Post deleted successfully.");
        } else {
          console.error("Unexpected response status:", response.status);
          console.error("Response data:", response.data);
          alert("There was an error deleting the post. Please check the console for more details.");
        }
      } catch (error) {
        console.error("Error object:", error);
        console.error("Response data:", error.response?.data);
        console.error("Error message:", error.message);
        alert("There was an error deleting the post. Please check the console for more details.");
      }
    }
  };

  return (
    <div className="blogshowlizt">
      <Container>
        <Row>
          <Col xs={12}> 
            <Table responsive striped bordered hover className="bloglitview">
              <thead>
                <tr>
                  <th width="50px">Id</th>
                  <th>Title</th>
                  <th>Shorttitle</th>
                  <th width="75px">Image</th>
                  <th>Category</th>                
                  {/* <th>Date</th>
                  <th>Time</th> */}
                  <th>Tags</th>
                  <th>Body</th>
                  <th width="90px">Actions</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(filteredPosts) && filteredPosts.length > 0 ? (
                  filteredPosts.map((post) => (
                    <tr key={post.id}>
                      <td>{post.id}</td>
                      <td>{post.title}</td>
                      <td className="tdshow2">{post.shorttitle}</td>
                      <td className="blgtbimg">
                        <img src={post.image}/>
                      </td>
                      <td>{post.category}</td>
                      {/* <td>{post.created_date}</td>
                      <td>{post.created_time}</td> */}
                      <td>{post.tags}</td>
                      <td className="tdshow2">{post.body}</td>
                      <td className="statact"> 
                        <Button
                          variant="warning"
                          onClick={() => handleEdit(post.id)}
                          className="stedtbtn"
                        >
                          <FaPencilAlt />
                        </Button>
                        <Button
                          variant="danger"
                          onClick={() => handleDelete(post.id)}
                          className="stdelbtn">
                          <FaTrashAlt />
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8">No posts available.</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BlogListBox;
