import React from 'react'
import Inban from '../components/inban/inban' 

function about() {

  const pageclass = "inbanz2"; // Data for bantitle
  const pagetitle = "About Us"; // Data for bantitle
  const pagedescription = "Anyone can post"; // Data for bandescription

  return (
    <div> 
      <Inban pageclass={pageclass}  pagetitle={pagetitle} pagedescription={pagedescription} /> 
    </div>
  )
}

export default about