import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Navbar, Nav, Button, Container, Row, Col } from 'react-bootstrap';

import { FaUser, FaAngleDown, FaUserEdit, FaBlog, FaUserLock, FaUserGraduate, FaUserCheck  } from "react-icons/fa";

import mainlogo from '../../images/logo.png';
import './header.css';

const Header = ({ isLoggedIn, setIsLoggedIn, setUser, user }) => {
  const handleLogout = () => {
    setIsLoggedIn(false);
    setUser(null);
    localStorage.removeItem('user');
  };

  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  return (
    <div className="commonhead">
      <Container>
        <Row>
          <Col>
            <Navbar.Brand href="/">
              <img src={mainlogo} alt="" className="headlogo" />
            </Navbar.Brand>
          </Col>
          <Col xs={5}>
            <Navbar expand="lg">
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="m-auto mainnav"> 
                  <Nav.Link as={NavLink} activeClassName="active" to="/" >Home</Nav.Link>
                  <Nav.Link as={NavLink} activeClassName="active" to="/about">About</Nav.Link>
                  <Nav.Link as={NavLink} activeClassName="active" to="/blog">Blog</Nav.Link>
                  {/* <Nav.Link as={NavLink} activeClassName="active" to="/news">News</Nav.Link> */}
                  <Nav.Link as={NavLink} activeClassName="active" to="/contact">Contact</Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </Col>
          <Col>
            {isLoggedIn && user ? ( 

              <div className="d-flex align-items-center showafterlogin">

            <div className="headright">
                <Link to="/addblog">
                    <Button className="blogbtn" variant="primary"> <FaBlog /> Add Blog</Button>
                </Link>
            </div> 
            <div className="media media-middle media-circle">
              <div className="lead-text" onClick={toggleDropdown}> <FaUser /> {user.fullname} <FaAngleDown /> </div> 
              {isDropdownVisible && (
                <div className="dropdown-content dropdown-content-x-lg headdropz pt-3 border-bottom border-light">
                  <ul className="link-list">
                    <li>
                      <Link to="/profile">
                      <FaUserEdit /> <span>My Profile</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/bloglist">
                      <FaBlog /> <span>My Posts</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/" onClick={handleLogout}>
                      <FaUserLock /> <span>Logout</span>
                      </Link>
                    </li>
                  </ul>
                </div> 
              )}
              </div>               
            </div>
            ) : (
              <div className="headright">
                <Link to="/login">
                  <Button className="logbtn"> <FaUserCheck />  Login</Button>
                </Link>{' '}
                <Link to="/register">
                  <Button className="regbtn"> <FaUserGraduate /> Register</Button>
                </Link> 
                <Link to="/login">
                    <Button className="blogbtn"> <FaBlog /> Add Blog</Button>
                </Link>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Header;
