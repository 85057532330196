import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useForm, Controller } from "react-hook-form";
import { Checkbox } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import axios from 'axios';

import logoz from '../../images/logo.png';
import './registerbox.css';

function Registerbox() {
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate(); // Initialize navigate

    const {
        register,
        handleSubmit, 
        control,
        formState: { errors }
    } = useForm({
        defaultValues: {
            FullName: "",
            Gender: "",
            Mobile: "", 
            Email: "",
            Password: "",
            MyCheckbox: false
        }
    });

    const onSubmit = async (data) => {
        try {
            const response = await axios.post('https://verbatdemos.com/clickpost/api/endpoints/register.php', data);
            console.log(response.data);
            setSuccessMessage('Registered successfully!');
            setErrorMessage('');

            // Auto-close message after 3 seconds and navigate to login
            setTimeout(() => {
                setSuccessMessage('');
                navigate('/login'); // Redirect to login page
            }, 1000);
        } catch (error) {
            console.error('There was an error registering:', error.response ? error.response.data : error.message);
            setErrorMessage('Registration failed: ' + (error.response ? error.response.data : error.message));
            setSuccessMessage('');
        }
    };

    return (
        <div className="regbgbox">
            <Container>
                <Row>
                    <Col> </Col>
                    <Col xs={6}>
                        <div className="fullregbox">
                            <div className="inregbox">
                                <div className="logoshowbox">
                                    <img src={logoz} alt="" />
                                </div>
                                {successMessage && <div className="alert alert-success">{successMessage}</div>}
                                {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                                <form onSubmit={handleSubmit(onSubmit)}> 
                                    <input placeholder="Enter Name"
                                        {...register("FullName", {
                                            required: true,
                                            maxLength: 20,
                                            pattern: /^[A-Za-z]+$/i,
                                        })}
                                    />
                                    {errors?.FullName?.type === "required" && <p>This field is required</p>}
                                    {errors?.FullName?.type === "maxLength" && <p>First name cannot exceed 20 characters</p>}
                                    {errors?.FullName?.type === "pattern" && <p>Alphabetical characters only</p>}
 
                                    <select {...register("Gender", { required: true })}>
                                        <option value="" disabled selected hidden>Select Gender</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="other">other</option>
                                    </select>
                                    {errors.Gender && <p>This field is required</p>}

                                    <input type="number" placeholder="Enter Mobile" {...register("Mobile", { required: true, minLength: 10, maxLength: 10 })} />
                                    {errors.Mobile && <p>Mobile number must be exactly 10 digits</p>} 
                                     
                                    <input placeholder="Enter Email" {...register("Email", { required: true, pattern: /^\S+@\S+$/i })} />
                                    {errors.Email && <p>Valid email is required</p>}
 
                                    <input placeholder="Enter password"
                                        type="Password"
                                        {...register("Password", {
                                            required: "You must specify a password",
                                            minLength: {
                                                value: 8,
                                                message: "Password must have at least 8 characters",
                                            },
                                        })}
                                    />
                                    {errors.Password && <p>{errors.Password.message}</p>}

                                    <Controller
                                        name="MyCheckbox"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox 
                                                        {...field} 
                                                        checked={field.value}
                                                        onChange={(e) => field.onChange(e.target.checked)} 
                                                    />
                                                }
                                                label="Accept my Terms and Conditions"
                                            />
                                        )}
                                    />
                                    {errors.MyCheckbox && <p>You must accept the terms and conditions</p>}

                                    <input className="subbox" type="submit" />
                                </form>

                                <div className="regbotbox">
                                    Already have an account? <Link to="/login">Login</Link>
                                </div>

                            </div>
                        </div>
                    </Col>
                    <Col> </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Registerbox;
