import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import Loginbox from '../components/loginbox/Loginbox';

function Login({ isLoggedIn, setIsLoggedIn, setUser }) {
  const navigate = useNavigate();
  
  if (isLoggedIn) {
    navigate('/');
  }

  return (
    <div>
      <Loginbox setIsLoggedIn={setIsLoggedIn} setUser={setUser} /> 
    </div>
  );
}

export default Login;
