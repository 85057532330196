import React from 'react'
import Blogeditbox from '../components/blogeditbox/blogeditbox';
import Inban from '../components/inban/inban'

const BlogEdit = () => {
    const pageclass = "inbanz6"; // Data for bantitle
    const pagetitle = "Edit your Blog"; // Data for bantitle
    const pagedescription = "Anyone can post"; // Data for bandescription

  return (
    <div>
        <Inban pageclass={pageclass}  pagetitle={pagetitle} pagedescription={pagedescription} />  
        <Blogeditbox/>
    </div>
  )
}

export default BlogEdit