import React from 'react';
import { Link } from 'react-router-dom';

import { FaUserAlt, FaCalendarAlt, FaClock } from "react-icons/fa";

import './postdetcard.css';

const PostDetCard = (post) => {
  if (!post) return null; // Ensure post is not undefined

  console.log(post)

  // const { image, category, title, shorttitle, Date, Time, body, tags = [] } = post;

  return (
    <div className="post-det-card">
        
          <img src={post.post.image} alt={post.post.title} className="post-card__image" />
          <h2 className="post-det-card__title">{post.post.title}</h2>
          <div className="post-det-card__meta">
              <span><FaUserAlt /> {post.post.fullname}</span>
              <span><FaCalendarAlt /> {post.post.created_date}</span>
              <span> <FaClock />  {post.post.created_time}</span>
          </div>
          <div className="post-det-card__content">
            <Link to={`/`} className="post-det-card__category">
            {post.post.category}
            </Link>
          </div>
          <h3 className="post-det-card__shorttitle">{post.post.shorttitle}</h3>
          <div className="post-det-card__body">{post.post.body}</div>
          
          <div className="post-det-card__tags">
            <h6 className="post-det-card__tag">
              <span>{post.post.tags}</span>
              
            </h6>  
          </div>
     
    </div>
  );
};

export default PostDetCard;
