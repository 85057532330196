import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

import './inban.css'



const inban = ({pagetitle, pagedescription, pageclass}) => {
  return (  
    <div className={`inbanz ${pageclass}`}>
        <Container>
            <Row>
                <Col>
                    <div className="innerbanz">
                        <h3>{pagetitle}</h3>
                        <p> {pagedescription} </p>
                    </div>                    
                </Col>
            </Row>
        </Container> 
    </div>
  )
}

export default inban