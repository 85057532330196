import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import axios from 'axios';

import './categoryslider.css';

import catimg1 from '../../images/catimg1.jpg';
import catimg2 from '../../images/catimg2.jpg';
import catimg3 from '../../images/catimg3.jpg';
import catimg4 from '../../images/catimg4.jpg';
import catimg5 from '../../images/catimg5.jpg';
import catimg6 from '../../images/catimg6.jpg';
import catimg7 from '../../images/catimg7.jpg';
import catimg8 from '../../images/catimg8.jpg';


const initialCategories = [
  { id: 1, name: 'Food', imageUrl: catimg1 },
  { id: 2, name: 'Travel', imageUrl: catimg2 },
  { id: 3, name: 'Lifestyle', imageUrl: catimg3 },
  { id: 4, name: 'Technology', imageUrl: catimg4 },
  { id: 5, name: 'Health', imageUrl: catimg5 },
  { id: 6, name: 'Interior', imageUrl: catimg6 },
  { id: 7, name: 'Fashion', imageUrl: catimg7 },
  { id: 8, name: 'Sports', imageUrl: catimg8 },
];

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 7
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const CategorySlider = () => {
  const [categories, setCategories] = useState(initialCategories);

  useEffect(() => {
    const fetchCategoryCounts = async () => {
      try {
        const response = await axios.get('https://verbatdemos.com/clickpost/api/endpoints/view_posts.php');
        const categoryCounts = response.data.data.reduce((acc, post) => {
          const category = post.category; // Adjust this based on your API response structure
          if (acc[category]) {
            acc[category]++;
          } else {
            acc[category] = 1;
          }
          return acc;
        }, {});

        const updatedCategories = initialCategories.map(category => ({
          ...category,
          count: categoryCounts[category.name] || 0
        }));

        setCategories(updatedCategories);
      } catch (error) {
        console.error("There was an error fetching the category counts!", error);
      }
    };

    fetchCategoryCounts();
  }, []);


  return (
    <div className='catslidz'>
      <Container>
        <Row>
          <Col>
            <div className="headz">
              <h2> Top trending topics </h2>
              <p> Discover over <span>{categories.length}</span> topics </p>
            </div> 
          </Col>
        </Row>
        <Row>
          <Col>
             <Carousel responsive={responsive}>
              {categories.map(category => (
                <div className="category-item" key={category.id}>
                  <img className="category-image" src={category.imageUrl} alt={category.name} />
                  <h5 className="category-count">{category.count}</h5>
                  <h3 className="category-name">{category.name}</h3>
                </div>
              ))}
            </Carousel>
          </Col>
        </Row>
      </Container>
      
    </div>
  );
};

export default CategorySlider;
