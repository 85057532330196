import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logoz from '../../images/logo.png';
import './loginbox.css';
import axios from 'axios';

function Loginbox({ setIsLoggedIn, setUser }) {
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const navigate = useNavigate();
   
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: { 
            Email: "",
            Password: ""
        }
    });

    const onSubmit = async (data) => {
        const formData = new FormData();
        formData.append('Email', data.Email);
        formData.append('Password', data.Password);

        try {
            const response = await axios.post('https://verbatdemos.com/clickpost/api/endpoints/login.php', formData);
            if (response.status === 200) {
                localStorage.setItem("user", JSON.stringify(response.data.data));
                localStorage.setItem("username",response.data.data.fullname); 
                setUser(response.data.data);
                setIsLoggedIn(true);
                setSuccessMessage('Login successful!');
                setErrorMessage('');

                setTimeout(() => {
                    setSuccessMessage('');
                    navigate('/');
                }, 1000);
            } else {
                setErrorMessage('Invalid email or password.');
                setSuccessMessage('');
            }
        } catch (error) {
            console.error('There was an error logging in:', error);
            setErrorMessage('There was an error logging in. Please try again.');
            setSuccessMessage('');
        }
    };


    

    return (
        <div className="logbgbox">
            <Container>
                <Row>
                    <Col></Col>
                    <Col xs={6}>
                        <div className="fulllogbox">
                            <div className="inlogbox">
                                <div className="logoshowbox">
                                    <img src={logoz} alt="logo" />
                                </div>
                                {successMessage && <div className="alert alert-success">{successMessage}</div>}
                                {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <input placeholder="Email / Username" type="email" {...register("Email", { required: true })} />
                                    {errors.Email && <span style={{ color: "red" }}>*Email* is mandatory</span>}
                                    <input placeholder="Password" type="password" {...register("Password", { required: true })} />
                                    {errors.Password && <span style={{ color: "red" }}>*Password* is mandatory</span>}
                                    <div className="form-options">
                                        <div className="remember-me">
                                            <input
                                                type="checkbox"
                                                id="rememberMe"
                                                checked={rememberMe}
                                                onChange={() => setRememberMe(!rememberMe)}
                                            />
                                            <label htmlFor="rememberMe"> Remember Me</label>
                                        </div>
                                        <div className="forgot-password">
                                            <Link to="/forgot">Forgot Password?</Link>
                                        </div>
                                    </div>
                                    <input className="subbox" type="submit" value="Login" />
                                </form>
                                <div className="logbotbox">
                                    Don't have an account? <Link to="/register">Register</Link>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col></Col>
                </Row>
            </Container>
        </div>
    );
}

export default Loginbox;
