import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; 
import Home from './routes/Home';
import About from './routes/About';
import Blog from './routes/Blog';
// import News from './routes/News';
import Contact from './routes/Contact';
import Loginbox from './routes/Login'; 
import Register from './routes/Register';
import Forgot from './routes/Forgot';
import Profile from './routes/Profile';
import AddBlog from './routes/AddBlog';
import BlogDetail from './routes/BlogDetail';
import BlogList from './routes/BlogList';
import BlogEdit from './routes/BlogEdit';
import Header from './components/header/Header'; 
import Footer from './components/footer/Footer';

import './App.css';  

function App() {
  const storedUser = localStorage.getItem('user');
  // console.log(storedUser)
  const [isLoggedIn, setIsLoggedIn] = useState(false);  
  const [user, setUser] = useState(storedUser ? JSON.parse(storedUser) : null);
  // console.log(localStorage.getItem('username'))
  useEffect(() => {
    if (storedUser) {
      // setUser(JSON.parse(storedUser));
      setIsLoggedIn(true);
    }
  }, []);

  return (
    <Router> 
      <Header isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} user={user} setUser={setUser} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog" element={<Blog />} />
        {/* <Route path="/news" element={<News />} /> */}
        <Route path="/contact" element={<Contact />} />
        <Route path="/login" element={<Loginbox setIsLoggedIn={setIsLoggedIn} setUser={setUser} />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/addblog" element={isLoggedIn ? <AddBlog /> : <Loginbox setIsLoggedIn={setIsLoggedIn} setUser={setUser} />} />
        <Route path="/post" element={<BlogDetail />} />
        <Route path="/bloglist" element={<BlogList />} /> 
        <Route path='/blogedit/:id' element={<BlogEdit />} />
        <Route path="/bloglist" element={<BlogList />} />   
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
