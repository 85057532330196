import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './blogeditbox.css';

const Blogeditbox = () => {
  
  const { id } = useParams();
  const navigate = useNavigate(); 
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(); // Set default image
  const [imageName, setImageName] = useState('');
  const [categories] = useState([
    "Food", "Travel", "Lifestyle", "Technology", "Health",
    "Interior", "Fashion", "Sports"
  ]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({
    defaultValues: {
      title: '',
      shorttitle: '',
      image: '',
      category: '',
      tags: [],
      body: ''
    }
  });

  // Function to decode HTML entities
  const decodeHTMLEntities = (text) => {
    const parser = new DOMParser();
    const decodedString = parser.parseFromString(text, 'text/html').body.textContent;
    return decodedString;
  };

  useEffect(() => {
    const fetchPostData = async () => {
      try {
        const response = await axios.get(`https://verbatdemos.com/clickpost/api/endpoints/view_single_post.php?post_id=${id}`);
        console.log(response.data); // Log response to inspect
        const postData = response.data.data;
        setValue('title', postData.title || '');
        setValue('shorttitle', postData.shorttitle || '');
        setValue('category', postData.category || '');
  
        // Decode the tags string before parsing
        const decodedTags = decodeHTMLEntities(postData.tags || '[]');
        setSelectedTags(JSON.parse(decodedTags));
  
        setValue('body', postData.body || '');
        
        if (postData.image) {
          setImagePreview(postData.image);
          setImageName(postData.image.split('/').pop()); // Extract filename from URL
          setValue('image', postData.image);
        }
        
  
      } catch (error) {
        console.error("There was an error fetching the post!", error);
      }
    };
  
    if (id) {
      fetchPostData();
    }
  }, [id, setValue]);

 

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setImageName(file.name);
      
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      // If no file is selected, clear the image preview and name
      setImage(null);
      setImageName('');
      setImagePreview(null);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && inputValue.trim()) {
      e.preventDefault();
      setSelectedTags([...selectedTags, inputValue.trim()]);
      setInputValue('');
    } else if (e.key === 'Backspace' && !inputValue && selectedTags.length) {
      e.preventDefault();
      setSelectedTags(selectedTags.slice(0, -1));
    }
  };

  const removeTag = (index) => {
    setSelectedTags(selectedTags.filter((_, i) => i !== index));
  };

  const onSubmit = async (data) => {
    try {
      const updatedFormData = new FormData();
      updatedFormData.append('title', data.title);
      updatedFormData.append('shorttitle', data.shorttitle);
      updatedFormData.append('category', data.category);
      updatedFormData.append('tags', JSON.stringify(selectedTags));
      updatedFormData.append('body', data.body);
      updatedFormData.append('id', id);

      if (image) {
        // If a new image was selected
        const base64 = await convertFileToBase64(image);
        updatedFormData.append('image', base64);
      } else if (imagePreview) {
        // If using the existing image
        updatedFormData.append('image', imagePreview);
      }
 
      await axios.post(`https://verbatdemos.com/clickpost/api/endpoints/update_post.php`, updatedFormData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setSuccessMessage('Updated successfully!');
       setErrorMessage('');

      setTimeout(() => {
        setSuccessMessage('');
        navigate('/bloglist'); // Redirect to login page
    }, 1000);
 
    } catch (error) {
      console.error("There was an error updating the post!", error);
    }
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  return (
    <div className='blog-edit'>
      <Container>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Title"
                  {...register("title", { required: "Title is required" })}
                />
                {errors.title && <span>{errors.title.message}</span>}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Short Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Short Title"
                  {...register("shorttitle", { required: "Short title is required" })}
                />
                {errors.shorttitle && <span>{errors.shorttitle.message}</span>}
              </Form.Group>
            </Col>
          </Row>

          <Row>

          
            <Col xs={4}>
            <Form.Group className="mb-3 blogedtimg">
              <Form.Label>Image</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                {...register("image", { required: !imagePreview })}
                onChange={handleImageChange}
              />
              {errors.image && <span>This field is required</span>}
              {imageName && <div className='litcurname'>Current file: {imageName}</div>}
              {imagePreview && (
                <div className="image-preview">
                  <img src={imagePreview} alt="Preview"/>
                </div>
              )}
            </Form.Group>
            </Col>
 

            <Col xs={4}>
              <Form.Group className="mb-3">
                <Form.Label>Category</Form.Label>
                <Form.Select
                  {...register("category", { required: "Category is required" })}
                >
                  <option value="">Select a category</option>
                  {categories.map((cat, index) => (
                    <option key={index} value={cat}>{cat}</option>
                  ))}
                </Form.Select>
                {errors.category && <span>{errors.category.message}</span>}
              </Form.Group>
            </Col>
           
            <Col xs={4}>
              <Form.Group className="mb-3">
                <Form.Label>Tags</Form.Label>
                <div className="multagz">
                  <div className="tag-input-container">
                    {selectedTags.map((tag, index) => (
                      <div className="tag" key={index}>
                        <input
                          type="hidden"
                          value={tag}
                          {...register(`tags[${index}]`)}
                        />
                        {tag}
                        <button type="button" onClick={() => removeTag(index)}>×</button>
                      </div>
                    ))}
                    <input
                      type="text"
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                      onKeyDown={handleKeyDown}
                      placeholder="Add a tag"
                    />
                  </div>
                </div>
                {errors.tags && <span>{errors.tags.message}</span>}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Body</Form.Label>
                <Controller
                  name="body"
                  control={control}
                  rules={{ required: "Body is required" }}
                  render={({ field }) => (
                    <CKEditor
                      editor={ClassicEditor}
                      data={field.value}
                      onChange={(event, editor) => {
                        field.onChange(editor.getData());
                      }}
                      onBlur={field.onBlur}
                      config={{
                        toolbar: [
                          "undo", "redo", "|", "heading", "|", "bold", "italic", "underline", "|",
                          "blockQuote", "|", "link", "|", "numberedList", "bulletedList", "|",
                          "insertTable", "mediaEmbed", "codeBlock"
                        ],
                        table: {
                          contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"]
                        }
                      }}
                    />
                  )}
                />
                {errors.body && <span>{errors.body.message}</span>}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="blogeditbtnx">
                <Button type="submit" className='updblgbtn'>
                  Update Blog Post
                </Button>
                <Button onClick={() => navigate('/bloglist')} className='backlistbtn'>
                  Back to List
                </Button>
              </div>

              {successMessage && <div className="alert alert-success">{successMessage}</div>}
              {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}

            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

export default Blogeditbox;