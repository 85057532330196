import React, { useState, useEffect } from "react";
import {Link} from 'react-router-dom'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import PostCard from '../postcard/postcard'; // Ensure you have a PostCard component

import './homeblog.css';

const HomeBlog = () => {
  const [posts, setPosts] = useState([]);

  const fetchPosts = async () => {
    try {
      const response = await axios.get('https://verbatdemos.com/clickpost/api/endpoints/view_posts.php');
      const fetchedPosts = response.data.data;

      // Sort posts by date and time in descending order (latest first)
      fetchedPosts.sort((a, b) => {
        const dateA = new Date(`${a.created_date} ${a.created_time}`);
        const dateB = new Date(`${b.created_date} ${b.created_time}`);
        return dateB - dateA;
      });

      // Take the first 6 posts
      const limitedPosts = fetchedPosts.slice(0, 6);
      
      setPosts(limitedPosts);
    } catch (error) {
      console.error("There was an error fetching the posts!", error);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, []);
  return (
    <div>
      <Container>
      <Row>
          <Col>
            <div className="headz">
              <h2> Latest Blogs </h2>
              <p> Discover over  topics </p>
            </div> 
          </Col>
        </Row>
        <Row>
          {Array.isArray(posts) && posts.length > 0 ? (
            posts.map((post) => ( 
              <Col xs={12} sm={6} md={4} key={post.user_id}>
                <PostCard
                  id={post.id} 
                  image={post.image}
                  category={post.category}
                  title={post.title}
                  shorttitle={post.shorttitle} 
                  date={post.created_date}  
                  time={post.created_time}
                  tags={post.tags}
                  fullname={post.fullname}
                />
              </Col>
            ))
          ) : (
            <Col xs={12}>
              <p>No posts available.</p>
            </Col>
          )}
        </Row>

        <Row>
          <Col> 
            <div className="allblogz">
                <Link className="allbloglinkz" to="/blog">
                   View Blogs 
                </Link>
            </div> 
          </Col>
        </Row>

      </Container>
    </div>
  );
};

export default HomeBlog;
