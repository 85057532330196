 import React, { useState, useEffect } from 'react'; 

import axios from 'axios';

import './catlist.css';

import catimg1 from '../../images/catimg1.jpg';
import catimg2 from '../../images/catimg2.jpg';
import catimg3 from '../../images/catimg3.jpg';
import catimg4 from '../../images/catimg4.jpg';
import catimg5 from '../../images/catimg5.jpg';
import catimg6 from '../../images/catimg6.jpg';
import catimg7 from '../../images/catimg7.jpg';
import catimg8 from '../../images/catimg8.jpg';


const initialCategories = [
  { id: 1, name: 'Food', imageUrl: catimg1 },
  { id: 2, name: 'Travel', imageUrl: catimg2 },
  { id: 3, name: 'Lifestyle', imageUrl: catimg3 },
  { id: 4, name: 'Technology', imageUrl: catimg4 },
  { id: 5, name: 'Health', imageUrl: catimg5 },
  { id: 6, name: 'Interior', imageUrl: catimg6 },
  { id: 7, name: 'Fashion', imageUrl: catimg7 },
  { id: 8, name: 'Sports', imageUrl: catimg8 },
];

 


const Catlist = () => {
    const [categories, setCategories] = useState(initialCategories);

    useEffect(() => {
        const fetchCategoryCounts = async () => {
          try {
            const response = await axios.get('https://verbatdemos.com/clickpost/api/endpoints/view_posts.php');
            const categoryCounts = response.data.data.reduce((acc, post) => {
              const category = post.category; // Adjust this based on your API response structure
              if (acc[category]) {
                acc[category]++;
              } else {
                acc[category] = 1;
              }
              return acc;
            }, {});
    
            const updatedCategories = initialCategories.map(category => ({
              ...category,
              count: categoryCounts[category.name] || 0
            }));
    
            setCategories(updatedCategories);
          } catch (error) {
            console.error("There was an error fetching the category counts!", error);
          }
        };
    
        fetchCategoryCounts();
      }, []);


  return (
    <>
    <div className="headz">
              <h2> Categories </h2>
              <p> Discover over <span>{categories.length}</span> topics </p>
            </div> 
    <div>
        {categories.map(catlist => (
                <div className="catlist-item" key={catlist.id}>
                  <img className="catlist-image" src={catlist.imageUrl} alt={catlist.name} />
                  <h5 className="catlist-count">{catlist.count}</h5>
                  <h3 className="catlist-name">{catlist.name}</h3>
                </div>
              ))}
    </div>
    </>
  )
}

export default Catlist