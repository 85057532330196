import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import logoz from '../../images/logo.png';

import './forgotbox.css'

const Forgotbox = () => {
    const [message, setMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
        try {
            const response = await axios.post('http://localhost:3005/forgotpassword', { email: data.Email });
            
            if (response.data.success) {
                setMessage('Password reset link sent to your email.');
                setErrorMessage('');
                setTimeout(() => {
                    setMessage('');
                    navigate('/login');
                }, 3000);
            } else {
                setErrorMessage('No account found with that email.');
                setMessage('');
            }
        } catch (error) {
            console.error('There was an error sending the reset link:', error);
            setErrorMessage('There was an error sending the reset link. Please try again.');
            setMessage('');
        }
    };

    return (
        <div className="forgbox">
            <Container>
                <Row>
                    <Col xs={12} md={6} className="offset-md-3">
                        <div className="forgot-password-box">

                            <div className="logoshowbox">
                                    <img src={logoz} alt="logo" />
                                </div>
                            {message && <div className="alert alert-success">{message}</div>}
                            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <input 
                                    placeholder="Enter your Email" 
                                    type="email" 
                                    {...register("Email", { required: true })} 
                                />
                                {errors.Email && <span style={{ color: "red" }}>*Email* is mandatory</span>}
                                <input className="submit-btn" type="submit" value="Send" />
                            </form>
                            <div className="back-to-login">
                              Back to <Link to="/login">Login</Link>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Forgotbox;
