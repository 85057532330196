import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useForm, Controller } from "react-hook-form";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"; 
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import "./addblogz.css";


// const defaultImage = "https://via.placeholder.com/40";

const generatePostID = () => {
  return `blog${Math.floor(Math.random() * 10000)}`;
};

const AddBlogz = () => {
  const [categories, setCategories] = useState([]);  
  const [selectedTags, setSelectedTags] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [userID, setUserID] = useState(null);
  const [authorName, setAuthorName] = useState(""); 
  const navigate = useNavigate();

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      body: "",
      user_id: generatePostID(),
      fullname: authorName, 
    },
  });

  useEffect(() => {
    // Mock categories for the example
    setCategories(["Food", "Travel", "Lifestyle", "Technology", "Health", "Interior", "Fashion", "Sports"]); 

    // Retrieve user data from localStorage
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setUserID(user.id); // Replace 'id' with the correct key from your user object
      setAuthorName(user.fullname || ""); // Set author name from localStorage 
      setValue("fullname", user.fullname || ""); // Update the form value for fullname
    }
  }, [setValue]);

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const getCurrentTime = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
    
    // Create a URL for the image preview
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && inputValue) {
      event.preventDefault();
      if (!selectedTags.includes(inputValue)) {
        setSelectedTags([...selectedTags, inputValue]);
        setInputValue('');
      }
    } else if (event.key === 'Backspace' && !inputValue && selectedTags.length) {
      const newTags = [...selectedTags];
      newTags.pop();
      setSelectedTags(newTags);
    }
  };

  const removeTag = (index) => {
    setSelectedTags(selectedTags.filter((tag, i) => i !== index));
  };

  const [inputValue, setInputValue] = useState('');
  const onSubmit = async (data) => {
    try {
      const jsonData = { ...data, tags: JSON.stringify(selectedTags) };

      const formData = new FormData();
      formData.append('title', jsonData.title);
      formData.append('shorttitle', jsonData.shorttitle);
      formData.append('category', jsonData.category);
      formData.append('user_id', userID); // Use the user_id retrieved from localStorage
      formData.append('Date', getCurrentDate()); // Use getCurrentDate to ensure correct format
      formData.append('Time', getCurrentTime());
      formData.append('tags', jsonData.tags);
      formData.append('body', jsonData.body);
      formData.append('fullname', jsonData.fullname);

      if (imageFile) {
        const base64 = await convertFileToBase64(imageFile);
        formData.append('imageFile', base64); // Append base64 string
      }

      await axios.post("https://verbatdemos.com/clickpost/api/endpoints/create_post.php", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      setSuccessMessage("Blog post added successfully!");

      setTimeout(() => {
        navigate('/bloglist');
      }, 1000);
    } catch (error) {
      console.error("There was an error submitting the form!", error);
      setSuccessMessage("Error: " + (error.response?.data?.message || error.message));
    }
  };

  const closeSuccessMessage = () => {
    setSuccessMessage("");
  };

  return (
    <div className="addnewblogz">
      <Container>
        <Row> 
          <Col>
            <form className="addblogform" onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col xs={12}>
                  <div>
                    <label>Title:</label>
                    <input {...register("title", { required: true })} />
                    {errors.title && <span>This field is required</span>}
                  </div>
                </Col>
                <Col xs={12}>
                  <div>
                    <label>Short Description:</label>
                    <input {...register("shorttitle", { required: true })} />
                    {errors.shorttitle && <span>This field is required</span>}
                  </div>
                </Col>
                <Col xs={4}> 
                  <div className="addupimg">
                    <label>Upload Image:</label>
                    <input
                      type="file"
                      accept="image/*"
                      {...register("imageFile", { required: true })}
                      onChange={handleImageChange}
                    />
                    {errors.imageFile && <span>This field is required</span>}
                    {imagePreview && (
                      <div className="image-preview">
                        <img src={imagePreview} alt="Preview" />
                      </div>
                    )}
                  </div>
                </Col>
                <Col xs={4}>
                  <div>
                    <label>Category:</label>
                    <select {...register("category", { required: true })}>
                      <option value="">Select a category</option>
                      {categories.map((category, index) => (
                        <option key={index} value={category}>
                          {category}
                        </option>
                      ))}
                    </select>
                    {errors.category && <span>This field is required</span>}
                  </div>
                </Col>
                <Col xs={4}>
                  <div className="multagz">
                    <label>Tags: <span>Hit 'Enter' to add new tags</span> </label>
                    <div className="tag-input-container">
                      {selectedTags.map((tag, index) => (
                        <div className="tag" key={index}>
                          {tag}
                          <button type="button" onClick={() => removeTag(index)}>×</button>
                        </div>
                      ))}
                      <input
                        type="text"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        onKeyDown={handleKeyDown}
                        placeholder="Add a tag"
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={3}>
                  <div>
                    <label>Post ID:</label>
                    <input
                      {...register("user_id", { required: true })}
                      readOnly
                    />
                    {errors.user_id && <span>This field is required</span>}
                  </div>
                </Col>
                <Col xs={3}>
                  <div>
                    <label>Post Date:</label>
                    <input
                      type="text"
                      {...register("Date", { required: true })}
                      defaultValue={getCurrentDate()}
                      readOnly
                    />
                    {errors.Date && <span>This field is required</span>}
                  </div>
                </Col>
                <Col xs={3}>
                  <div>
                    <label>Post Time:</label>
                    <input
                      type="time"
                      {...register("Time", { required: true })}
                      defaultValue={getCurrentTime()}
                      readOnly
                    />
                    {errors.Time && <span>This field is required</span>}
                  </div>
                </Col>
                <Col xs={3}>
                  <div>
                    <label>Author Name:</label>
                    <input
                      type="text"
                      {...register("fullname")}
                      value={authorName}
                      readOnly
                    />
                    {errors.fullname && <span>This field is required</span>}
                  </div>
                </Col> 
                <Col xs={12}>
                  <div>
                    <label>Body:</label>
                    <Controller
                      name="body"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <CKEditor
                          editor={ClassicEditor}
                          data={value}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            onChange(data);
                          }}
                          onBlur={onBlur}
                          config={{
                            toolbar: [
                              "undo",
                              "redo",
                              "|",
                              "heading",
                              "|",
                              "bold",
                              "italic",
                              "underline",
                              "|",
                              "blockQuote",
                              "|",
                              "link",
                              "|",
                              "numberedList",
                              "bulletedList",
                              "|",
                              "insertTable",
                              "mediaEmbed",
                              "codeBlock",
                            ],
                            table: {
                              contentToolbar: [
                                "tableColumn",
                                "tableRow",
                                "mergeTableCells",
                              ],
                            },
                          }}
                        />
                      )}
                    />
                    {errors.body && <span>This field is required</span>}
                  </div>
                </Col>
                <Col>
                  <button className="addblogbtn" type="submit">Add Blog Post</button>
                  {successMessage && (
                    <div className="success-message">
                      <span>{successMessage}</span>
                      <button onClick={closeSuccessMessage}>Close</button>
                    </div>
                  )}
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddBlogz;
