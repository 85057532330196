import React from 'react'
import Inban from '../components/inban/inban'
import BlogList from '../components/bloglistbox/bloglistbox' 

const bloglist = () => {

  const pageclass = "inbanz5"; // Data for bantitle
  const pagetitle = "Blog Lists"; // Data for bantitle
  const pagedescription = "Anyone can post"; // Data for bandescription

  return (
    <div>
        <Inban pageclass={pageclass}  pagetitle={pagetitle} pagedescription={pagedescription} />
        <BlogList/> 
    </div>
  )
}

export default bloglist
