import React, { useState, useEffect } from 'react'; 
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import './profilecard.css'; // Ensure this file contains your custom styles
import axios from 'axios'; 

const ProfileCard = ({ user }) => {
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(user?.image); // Set default image

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const { register, handleSubmit, formState: { errors }, setValue } = useForm({
    defaultValues: {
      FullName: user?.fullName || '', 
      Gender: user?.gender || '', 
      Mobile: user?.mobile || '',
      Email: user?.email || '',
      Location: user?.location || '',
      Qualification: user?.qualification || '',
      Bio: user?.bio || '',
    }
  });

  useEffect(() => {
    setValue('FullName', user?.fullname || '');
    setValue('Gender', user?.gender || '');
    setValue('Mobile', user?.mobile || '');
    setValue('Email', user?.email || '');
    setValue('Location', user?.location || '');
    setValue('Qualification', user?.qualification || '');
    setValue('Bio', user?.bio || '');
    setValue('Image', user?.image || '');
  }, [user, setValue]);

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append('user_id', user.id);
      formData.append('fullname', data.FullName);
      formData.append('gender', data.Gender);
      formData.append('mobile', data.Mobile);
      formData.append('email', data.Email);
      formData.append('location', data.Location);
      formData.append('qualification', data.Qualification);
      formData.append('bio', data.Bio);
      
      if (image) {
        const base64 = await convertFileToBase64(image);
        formData.append('image', base64); 
      }
 

      const response = await axios.post(
        'https://verbatdemos.com/clickpost/api/endpoints/update_profile.php',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      console.log(data);
      console.log('API Response:', response.data);

      if (response.data.status===200) {
        setSuccessMessage('Profile updated successfully!');
        setErrorMessage('');
        setImagePreview(response.data.image); // Update image preview
      } else {
        setErrorMessage('Failed to update profile');
        setSuccessMessage('');
      }
    } catch (error) {
      console.error('There was an error updating the profile:', error.response ? error.response.data : error.message);
      setErrorMessage('Profile update failed: ' + (error.response ? error.response.data : error.message));
      setSuccessMessage('');
    }
  };

  if (!user) return null;

  return (
    <div className="profilecardshow">
      <Container>
        <form onSubmit={handleSubmit(onSubmit)}> 

          <Row>
            <Col>
              <div className="profzdata profcap">
                <h6>Full Name</h6>
                <input
                  placeholder="Enter Name"
                  {...register("FullName", {
                    required: "Full Name is required",
                    maxLength: { value: 20, message: "Maximum length is 20 characters" },
                    pattern: { value: /^[A-Za-z]+$/i, message: "Only letters are allowed" },
                  })}
                />
                {errors.FullName && <span>{errors.FullName.message}</span>}
              </div>
            </Col>
            <Col>
              <div className="profzdata avprof">
                <div className="addupimg">
                  <h6>Image:</h6>
                  <input
                    type="file"
                    accept="image/*"
                    {...register("image")}
                    onChange={handleImageChange}
                  />
                  {errors.imageFile && <span>This field is required</span>}
                  {imagePreview && (
                    <div className="image-preview">
                      <img src={imagePreview} alt="Preview" />
                    </div>
                  )}
                </div>
              </div>
            </Col>
            <Col>
              <div className="profzdata profcap">
                <h6>Gender</h6>
                <input
                  placeholder="Enter Gender"
                  {...register("Gender")}
                  disabled
                />
              </div>
            </Col>
            
          </Row>

          <Row>
          <Col>
              <div className="profzdata">
                <h6>Mobile</h6>
                <input
                  placeholder="Enter Mobile"
                  type="tel"
                  {...register("Mobile")}
                />
                {errors.Mobile && <span>{errors.Mobile.message}</span>}
              </div>
            </Col>
            <Col>
              <div className="profzdata">
                <h6>Email</h6>
                <input
                  placeholder="Enter Email"
                  type="email"
                  {...register("Email")}
                />
                {errors.Email && <span>{errors.Email.message}</span>}
              </div>
            </Col>

            <Col>
              <div className="profzdata">
                <h6>Location</h6>
                <input
                  placeholder="Enter Location"
                  {...register("Location")}
                />
                {errors.Location && <span>{errors.Location.message}</span>}
              </div>
            </Col>
            <Col>
              <div className="profzdata">
                <h6>Qualification</h6>
                <input
                  placeholder="Enter Qualification"
                  {...register("Qualification")}
                />
                {errors.Qualification && <span>{errors.Qualification.message}</span>}
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <div className="profzdata">
                <h6>Bio</h6>
                <textarea
                  placeholder="Enter Bio"
                  {...register("Bio")}
                />
                {errors.Bio && <span>{errors.Bio.message}</span>}
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <Button className="editprofbtn" type="submit">
                Save Profile
              </Button>
              {successMessage && <div className="alert alert-success">{successMessage}</div>}
              {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
            </Col>
          </Row>
        </form>
      </Container>
    </div>
  );
};

// Define PropTypes
ProfileCard.propTypes = {
  user: PropTypes.shape({
    image: PropTypes.string,
    fullname: PropTypes.string,
    gender: PropTypes.string,
    mobile: PropTypes.string,
    email: PropTypes.string,
    location: PropTypes.string,
    qualification: PropTypes.string,
    bio: PropTypes.string,
    id: PropTypes.string.isRequired, // Ensure id is a required string prop
  }).isRequired, // Add .isRequired to enforce that user prop is provided
};

export default ProfileCard;