import React from 'react' 
import Inban from '../components/inban/inban'

function contact() {

  const pageclass = "inbanz4"; // Data for bantitle
  const pagetitle = "Contact"; // Data for bantitle
  const pagedescription = "Anyone can post"; // Data for bandescription

  return (
    <div> 
      <Inban pageclass={pageclass}  pagetitle={pagetitle} pagedescription={pagedescription} />
    </div>
  )
}

export default contact