import React from 'react';
import HomeBlog from '../components/homeblog/homeblog';
import Slider from '../components/slider/Slider';
import Categoryslider from '../components/categoryslider/categoryslider';

function Home() {
  return (
    <div>
        <Slider />
        <Categoryslider/>
        <HomeBlog/>
    </div>
  );
}

export default Home;
