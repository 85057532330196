import React from 'react'
 
import Addblogz from  '../components/addblogz/addblogz'
import Inban from '../components/inban/inban'

function addblog() {

  const pageclass = "inbanz1"; // Data for bantitle
  const pagetitle = "Add New Blog"; // Data for bantitle
  const pagedescription = "Anyone can post"; // Data for bandescription

  return (
    <div> 
         <Inban pageclass={pageclass}  pagetitle={pagetitle} pagedescription={pagedescription} />
         <Addblogz/> 
    </div>
  )
}

export default addblog