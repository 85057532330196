import React from 'react';
import { Link } from 'react-router-dom';

import { FaUserEdit , FaCalendarAlt, FaClock } from "react-icons/fa";


import './postcard.css'; // Ensure you have this CSS file

const PostCard = (post, user) => {
  // const { id, image, category, title, shorttitle, Date, Time } = post;

  return (
    <div className="post-card post-card--default">
      <div className="post-card__image">
        <Link to={`/post?id=${post.id}`}>
          <img src={post.image} alt={post.title} />
        </Link>
      </div>

      <div className="post-card__content">
        <h6 className="category">{post.category}</h6>
        <h5 className="post-card__title">
          <Link to={`/post?id=${post.id}`} className="post-card__title-link">{post.title}</Link>
        </h5>
        <p className="post-card__excerpt">{post.shorttitle}</p>

        <ul className="post-card__meta list-inline">  
          <li className="post-card__meta-item">
             <FaUserEdit /> {post.fullname}
          </li>
          <li className="post-card__meta-item">
             <FaCalendarAlt /> {post.date}
          </li>
          <li className="post-card__meta-item">
            <FaClock /> {post.time}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PostCard;
