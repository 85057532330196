import React, { useState, useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import ProfileCard from '../profilecard/profilecard';
import './profilebox.css';

const Profilebox = () => { 
    const userprof = localStorage.getItem('user'); 
    const id = JSON.parse(userprof).id;

    const [user, setUser] = useState();  
    const [error, setError] = useState(null);

    const fetchPosts = async () => {
        try {
            const response = await axios.get(`https://verbatdemos.com/clickpost/api/endpoints/view_profile.php?user_id=${id}`); 
            setUser(response.data.data); // Update the user state with the entire response data
            console.log(response)
        } catch (error) {
            console.error("There was an error fetching the posts!", error);
            setError("There was an error fetching the posts! " + error.message);
        }
    };

    useEffect(() => { 

        
            fetchPosts(); 
    }, []);

    return (
        <div className="profileboxshow"> 
            <Container>
                <Row>
                    {error ? (
                        <Col xs={12}>
                            <p>{error}</p>
                        </Col>
                    ) : user ? (
                        <Col xs={12} key={user.user_id}>
                            <ProfileCard user={user} />
                        </Col>
                    ) : (
                        <Col xs={12}>
                            <p>Loading...</p>
                        </Col>
                    )} 
                </Row>
            </Container>
        </div>
    );
}

export default Profilebox;