import React from 'react'
import Inban from '../components/inban/inban'
import Profilebox from '../components/profilebox/profilebox' 

function profile() {

  const pageclass = "inbanz6"; // Data for bantitle
  const pagetitle = "Profiles"; // Data for bantitle
  const pagedescription = "Anyone can post"; // Data for bandescription

  return (
    <div> 
        <Inban pageclass={pageclass}  pagetitle={pagetitle} pagedescription={pagedescription} />
        <Profilebox/> 
    </div>
  )
}

export default profile