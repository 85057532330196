import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PostDetCard from "../postdetcard/postdetcard"; // Import your PostDetCard component
import axios from "axios";

import Catlist from '../catlist/catlist'
 

const BlogDetailBox = (prop) => {
  const [posts, setPosts] = useState([]);
 


  const fetchPosts = async () => {
    try {
      const response = await axios.get('https://verbatdemos.com/clickpost/api/endpoints/view_single_post.php?post_id='+prop.post_id);
      setPosts(response.data.data);
      // console.log(response.data.data); // Log the API response directly
    } catch (error) {
      console.error("There was an error fetching the posts!", error);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  useEffect(() => {
    // console.log(posts); // Log posts whenever it updates
  }, [posts]);

  return (
    <div className="blogfulldetz">
        <Container>
          <Row>
              <Col xs={10}>
                
                  {posts? ( 
                    <Col xs={12} key={posts.user_id}>
                      <div className="blogfullindetz">
                        <PostDetCard post={posts} />
                      </div>              
                    </Col>  
                ) : (
                  <Col xs={12}>
                    <p>No posts available.</p>
                  </Col>
                )}

                
              </Col>

              <Col> 
                  <Catlist/>
              </Col>

            
          </Row>
        </Container>
    </div>
    
  );
};

export default BlogDetailBox;
