import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import PostCard from '../postcard/postcard'; // Ensure you have a PostCard component
import Pagination from 'react-bootstrap/Pagination'; // Add Pagination component

import './blogs.css';

const Blogs = () => {
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 6;

  const fetchPosts = async () => {
    try {
      const response = await axios.get('https://verbatdemos.com/clickpost/api/endpoints/view_posts.php');
      const sortedPosts = response.data.data.sort((a, b) => {
        const dateA = new Date(`${a.created_date}T${a.created_time}`);
        const dateB = new Date(`${b.created_date}T${b.created_time}`);
        return dateB - dateA;
      });
      setPosts(sortedPosts);
      console.log(sortedPosts); // Log the sorted API response directly
    } catch (error) {
      console.error("There was an error fetching the posts!", error);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  // Calculate the posts for the current page
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  // Handle pagination click
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Generate pagination items
  const totalPages = Math.ceil(posts.length / postsPerPage);
  const paginationItems = [];
  for (let number = 1; number <= totalPages; number++) {
    paginationItems.push(
      <Pagination.Item key={number} active={number === currentPage} onClick={() => paginate(number)}>
        {number}
      </Pagination.Item>
    );
  }

  return (
    <div className="mainblogz">
      <Container>
        <Row>
          {currentPosts.length > 0 ? (
            currentPosts.map((post) => (
              <Col xs={12} sm={6} md={4} key={post.id}>
                <PostCard
                  id={post.id}
                  image={post.image}
                  category={post.category}
                  title={post.title}
                  shorttitle={post.shorttitle}
                  date={post.created_date}
                  time={post.created_time}
                  tags={post.tags}
                  fullname={post.fullname}
                />
              </Col>
            ))
          ) : (
            <Col xs={12}>
              <p>No posts available.</p>
            </Col>
          )}
        </Row>
        <Row>
          <Col xs={12}>
            <div className="blogpagimz">
              <Pagination>{paginationItems}</Pagination>
            </div>            
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Blogs;
