import React  from 'react' 
import Inban from '../components/inban/inban'
import { useSearchParams } from "react-router-dom";
 
import Blogdetailbox from '../components/blogdetailbox/blogdetailbox'

const Blogdetail = () => {

  const pageclass = "inbanz1"; // Data for bantitle
  const pagetitle = "Blog Details"; // Data for bantitle
  const pagedescription = "Anyone can post"; // Data for bandescription

  const [searchParams] = useSearchParams( );
  

  console.log(searchParams.get('id'))

  return (
    <div> 
        <Inban pageclass={pageclass}  pagetitle={pagetitle} pagedescription={pagedescription} />
        <Blogdetailbox post_id={searchParams.get('id')}/>
 
    </div>
  )
}

export default Blogdetail